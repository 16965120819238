import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  Page,
  Content,
  H3,
  Text,
  Spacer,
  Message,
  Row,
  BottomDocked,
  Button,
  colors,
} from '../components'
import { useNavigate } from 'react-router-dom'
import { Config } from '../const'
import { isIOS } from '../helper/userAgents'
import { InstallPrompt } from './InstallPrompt'
import { loadProperty } from '../helper/LocalStorage'

const Headline = styled(H3)`
  margin-top: 0;
`

let appIsInstalled = loadProperty('appInstalled') || false

if (
  window.matchMedia('(display-mode: standalone)').matches ||
  (window.navigator as any).standalone
) {
  // PWA is installed
  console.log('PWA is already installed')
  appIsInstalled = true
} else {
  // PWA is not installed
  console.log('PWA is not installed')
}

const nextPage = Config.REACT_APP_IS_FUSION ? '/no-photos' : '/timetable'

const Start = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (appIsInstalled) {
      navigate(nextPage)
    }
  }, [navigate])

  return (
    <Page>
      <Content color={colors.textAlt}>
        <Headline>
          <Message id="WELCOME_HEADLINE" />
        </Headline>

        {isIOS ? (
          <Text>
            <Message id="WELCOME_MESSAGE_IOS" renderRawHtml />
          </Text>
        ) : (
          <Text>
            <Message id="WELCOME_MESSAGE" renderRawHtml />
          </Text>
        )}

        <InstallPrompt appIsInstalled={appIsInstalled} />

        {Config.REACT_APP_SHOW_NOT_REALEASED && (
          <>
            <Spacer size={4} />
            <H3>
              <Message id="WELCOME_MESSAGE_NOT_RELEASED" />
            </H3>
          </>
        )}
      </Content>
      <BottomDocked>
        <Row>
          <Button
            disabled={Config.REACT_APP_SHOW_NOT_REALEASED}
            onClick={() => {
              navigate(nextPage)
            }}
          >
            <Message id="NEXT" />
          </Button>
          <Spacer size={2} />
        </Row>
      </BottomDocked>
    </Page>
  )
}

export default Start
