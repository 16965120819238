import React, { FC, useEffect, useMemo, useState } from 'react'
import Entry, { Act } from './Entry'
import { H3, Text, TextContent, Spacer } from '../components/Typo'
import Message from '../components/Message'
import TimeHeader from './TimeHeader'
import styled from 'styled-components'
import { device } from '../device'
import { useAppDispatch } from '../state/hooks'
import { changeFilter, openFilter } from '../state/filter/filterSlice'
import { toggleFav } from '../state/timetable/timetableSlice'

const Table = styled.table`
  width: 100%;
  /* margin-bottom: -4px; */
  border-spacing: 0;
`

const searchFieldHeight = 45

const StickyHeader = styled.th<{ $withSearch: boolean; $withDays: boolean }>`
  position: sticky;
  top: ${(props) => {
    let top = 0
    if (props.$withSearch) {
      top += searchFieldHeight
    }
    if (props.$withDays) {
      top += 68
    }
    return top
  }}px;

  @media ${device.mobileM} {
    top: ${(props) => {
      let top = 0
      if (props.$withSearch) {
        top += searchFieldHeight
      }
      if (props.$withDays) {
        top += 78
      }
      return top
    }}px;
  }

  z-index: 10;
`

const IsLoadingMessage = () => (
  <TextContent white>
    <Spacer size={4} />
    <Text>
      <Message id="LOADING" />
    </Text>
  </TextContent>
)

const NoEntriesMessage = ({
  isFavoritesList,
  isOrderByName,
}: {
  isFavoritesList: boolean
  isOrderByName: boolean
}) => (
  <TextContent white>
    <Spacer size={4} />
    <H3>
      <Message
        id={
          isFavoritesList
            ? 'FAVORITES_NO_ENTRIES_HEADLNE'
            : 'TIMETABLE_NO_ENTRIES_HEADLNE'
        }
      />
    </H3>
    <Spacer size={1} />
    <Text>
      <Message
        id={
          !isFavoritesList
            ? 'TIMETABLE_NO_ENTRIES_MESSAGE'
            : isOrderByName
            ? 'FAVORITES_NO_ENTRIES_MESSAGE'
            : 'FAVORITES_NO_ENTRIES_MESSAGE_DAY'
        }
      />
    </Text>
  </TextContent>
)

type Props = {
  type: 'favorites' | 'artists' | 'timetable'
  orderBy: 'time' | 'name'
  entries: Act[][]
  isLoading: boolean
  isSearch: boolean
  scrollToTime?: string
}

const Entries: FC<Props> = (props) => {
  const dispatch = useAppDispatch()

  const { entries, isLoading, isSearch, scrollToTime } = props

  const isFavoritesList = props.type === 'favorites'
  const isArtistsList = props.type === 'artists'
  const isOrderByName = props.orderBy === 'name'

  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    setFirstRender(false)
  }, [])

  const entriesToRender = useMemo(() => {
    return firstRender ? entries.slice(0, 1) : entries
  }, [entries, firstRender])

  const numberOfEntries = entriesToRender.length

  useEffect(() => {
    if (numberOfEntries > 1 && scrollToTime && !isSearch) {
      const element = document.querySelector(`[data-time='${scrollToTime}']`)
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'auto' })
      }
    }
  }, [scrollToTime, numberOfEntries, isSearch])

  if (isLoading) {
    return <IsLoadingMessage />
  }

  if (entries.length === 0) {
    return (
      <NoEntriesMessage
        isFavoritesList={isFavoritesList}
        isOrderByName={isOrderByName}
      />
    )
  }

  // console.log('Entries', entriesToRender)

  return (
    <>
      {entriesToRender.map((entriesOfHour, key) => {
        const firstAct = entriesOfHour[0]
        return (
          <Table
            key={key}
            data-time={
              isArtistsList || isOrderByName
                ? firstAct.artist.substring(0, 1).toUpperCase()
                : firstAct.from
            }
          >
            <thead>
              <tr>
                <StickyHeader
                  $withSearch={!isFavoritesList}
                  $withDays={!isOrderByName && !isSearch}
                >
                  <TimeHeader
                    from={
                      isArtistsList || isOrderByName
                        ? firstAct.artist.substring(0, 1).toUpperCase()
                        : firstAct.from
                    }
                    day={firstAct.day}
                    withExtras={isSearch && !isOrderByName}
                  />
                </StickyHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {entriesOfHour.map((act, index) => {
                    const isLast = index === entriesOfHour.length - 1
                    return (
                      <Entry
                        act={act}
                        key={index}
                        onClick={(id) => dispatch(toggleFav(id))}
                        isLast={isLast}
                        showTimeAndFloor={!isOrderByName}
                        onInfoClick={() => {
                          dispatch(
                            changeFilter({
                              filterId: 'info',
                              value: act.showId,
                            }),
                          )
                          dispatch(openFilter('info'))
                        }}
                      />
                    )
                  })}
                </td>
              </tr>
            </tbody>
          </Table>
        )
      })}
    </>
  )
}

export default Entries
