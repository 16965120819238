import * as R from 'ramda'
import {
  getFavorites,
  getTimetable,
  getArists,
  getArtistExtras,
  getArtistById,
} from '../state/timetable/timetableSlice'
import { createSelector } from 'reselect'
import { getFilters } from '../state/filter/filterSlice'
import { createAppSelector } from '../state/store'
import {
  Artist,
  ArtistExtraItem,
  ArtistWithExtras,
  Show,
  TimetableEntry,
} from '../state/timetable/types'

// const getEntries = createAppSelector(
//   [
//     // Type of `state` is set to `RootState`, no need to manually set the type
//     getTimetable,
//     getFavorites,
//     getFilters,
//     getArtistExtras,
//     (state: RootState, onlyFavorites: boolean) => onlyFavorites,
//   ],
//   (timetable, favorites, filters, extras, onlyFavorites) => {}
// )

const getEntries = createAppSelector(
  [
    // Type of `state` is set to `RootState`, no need to manually set the type
    getTimetable,
    getFavorites,
    getFilters,
    getArtistExtras,
    (_, onlyFavorites: boolean) => onlyFavorites,
  ],
  // eslint-disable-next-line max-params
  (timetable, favorites, filters, extras, onlyFavorites) => {
    // let entries = timetable.slice(0, 100)
    let entries = timetable

    // console.log('getEntries() #### onlyFavorites : ', {
    //   entries,
    //   day: filters.days,
    // })

    if (onlyFavorites) {
      entries = R.filter((entry: TimetableEntry) =>
        R.includes(entry.showId, favorites),
      )(entries)
      // filter by day
      entries = R.filter((entry: TimetableEntry) => entry.day === filters.days)(
        entries,
      )
    } else if (filters.search) {
      // console.log('search: ' + filters.search)
      entries = R.filter(
        (entry: TimetableEntry) =>
          entry.name?.toLowerCase().includes(filters.search.toLowerCase()) ||
          entry.artist.toLowerCase().includes(filters.search.toLowerCase()) ||
          entry.floor.toLowerCase().includes(filters.search.toLowerCase()),
      )(entries)
    } else {
      // filter by day
      // console.log('filrter by day', filters.days)
      entries = R.filter((entry: TimetableEntry) => entry.day === filters.days)(
        entries,
      )

      // filter by floor and type only for timetable
      if (!onlyFavorites) {
        // filter by floor
        if (filters.floors.length > 0) {
          entries = R.filter((entry: TimetableEntry) =>
            R.includes(entry.floor, filters.floors),
          )(entries)
        }

        // console.log('entries', entries)
        // filter by type
        if (filters.types.length > 0) {
          entries = R.filter((entry: TimetableEntry) =>
            R.includes(entry.genre, filters.types),
          )(entries)
        }

        if (filters.categories.length > 0) {
          entries = R.filter((entry: TimetableEntry) =>
            R.includes(entry.category, filters.categories),
          )(entries)
        }
        // console.log('entries', entries)
      }
    }

    // add isFavorite prop
    entries = R.map((entry: TimetableEntry) => {
      if (R.includes(entry.showId, favorites)) {
        return {
          ...entry,
          isFavorite: true,
        }
      }
      return entry
    })(entries)

    // add artist extras
    entries = R.map((entry: TimetableEntry) => {
      const extra = extras[entry.id]
      return {
        ...entry,
        ...extra,
      }
    })(entries)

    // console.log('getEntries() ', entries)

    return entries
  },
)

export const getEntriesForHours = createSelector(getEntries, (entries) => {
  return R.pipe(
    R.groupBy((entry: TimetableEntry) => entry.date + entry.from),
    R.values,
  )(entries)
})

export const getEntriesForArtists = createSelector(
  [
    getTimetable,
    getArists,
    getFavorites,
    getFilters,
    getArtistExtras,
    (_, onlyFavorites) => onlyFavorites,
  ],
  // eslint-disable-next-line max-params
  (timetable, artists, favorites, filters, artistExtras, onlyFavorites) => {
    // let entries = artists.slice(0, 100)
    // let entries = timetable
    let entries = artists

    // console.log({ timetable, artists })

    if (onlyFavorites) {
      entries = R.filter((entry: any) => R.includes(entry.showId, favorites))(
        entries,
      )
    }

    // console.log(entries)

    if (filters.search && !onlyFavorites) {
      // console.log('search: ' + filters.search)
      entries = R.filter(
        (entry: any) =>
          entry.name?.toLowerCase().includes(filters.search.toLowerCase()) ||
          entry.artist.toLowerCase().includes(filters.search.toLowerCase()),
        // ||
        // entry.floor.toLowerCase().includes(filters.search.toLowerCase())
      )(entries)
    } else {
      // filter by day
      // entries = R.filter(entry => entry.day === filters.days)(entries)

      // filter by floor
      // if (filters.floors.length > 0) {
      //   entries = R.filter((entry) => R.includes(entry.floor, filters.floors))(
      //     entries
      //   )
      // }

      // console.log('entries', entries)
      // filter by type
      if (filters.types.length > 0) {
        entries = R.filter((entry: Artist) =>
          R.includes(entry.genre, filters.types),
        )(entries)
      }
      // console.log('entries 3', entries)
    }

    // add isFavorite prop
    const artistWithExtras: ArtistWithExtras[] = R.map((entry: Artist) => {
      const extra = artistExtras[entry.id]
      if (R.includes(entry.showId, favorites)) {
        return {
          ...entry,
          isFavorite: true,
          ...extra,
        }
      }
      return {
        ...entry,
        ...extra,
      }
    })(entries)

    const resultArtists = R.pipe(
      R.sortWith([
        R.ascend((entry: ArtistWithExtras) => {
          return entry.artist
        }),
        // R.ascend(show => {
        //   return consts.floors.indexOf(show.floor)
        // }),
      ]),
      R.groupBy((entry) => entry.artist.substring(0, 1).toUpperCase()),
      R.values,
    )(artistWithExtras)

    // console.log('entries 5', resultArtists)

    return resultArtists
  },
)

// @ts-ignore
export const getArtistWithShows = createAppSelector(
  [getFavorites, (state, id) => getArtistById(state, id), getArtistExtras],
  (favorites, artist, artistExtras) => {
    // console.log({ favorites, artist })

    if (!artist) {
      return
    }

    const extras: ArtistExtraItem | undefined = artist?.id
      ? artistExtras[artist?.id]
      : undefined

    const shows = R.map((show: Show) => {
      if (R.includes(show.showId, favorites)) {
        return {
          ...show,
          isFavorite: true,
        }
      }
      return {
        ...show,
        isFavorite: false,
      }
    })(artist?.shows || [])

    const artistIsFavorite =
      // in shiow mode check the shows
      shows.some((show) => show.isFavorite) ||
      // in artist mode check the showId
      R.includes(artist.showId, favorites)

    const artistWithShows = {
      ...artist,
      shows,
      extras,
      isFavorite: artistIsFavorite,
    }

    // console.log('artistWithShows() ', artistWithShows)

    return artistWithShows
  },
)
export type ArtistWithShows = ReturnType<typeof getArtistWithShows>
