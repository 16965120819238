/* eslint-disable react-native/no-inline-styles */
import React from 'react'
import { BottomArea } from '../BottomArea/BottomArea'

import MapImage from '../images/map_attension_24.png'
import { Page } from '../components'

interface SimpleMapProps {
  // imageUrl: string
}

const SimpleMap: React.FC<SimpleMapProps> = () => {
  return (
    <Page>
      {/* <PageTitle title={<Message id="BUTTON_MAP" />} /> */}
      <div style={{ width: '100%', height: 'auto', overflow: 'scroll' }}>
        <div style={{ width: 1200, height: 'auto', overflow: 'scroll' }}>
          <img
            src={MapImage}
            alt="Zoomable map"
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      </div>
      <BottomArea showFilterButton={false} />
    </Page>
  )
}

export default SimpleMap
