import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Body1, Caption, colors } from '../components'

type Props = {
  title: ReactNode
  content: ReactNode
}

const Section = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid ${colors.SpecialContent.divider};
`

export const ArtistTextSection: React.FC<Props> = ({ title, content }) => {
  return (
    <Section>
      <Caption color={colors.textInverted}>{title}</Caption>
      <Body1>{content}</Body1>
    </Section>
  )
}
