import { FC, useState } from 'react'
import styled from 'styled-components'
import { FilterButton } from '../FilterMenu/FilterButton'
import { Coordinate } from '../Map/models/map'
import { Menu } from '../Menu/Menu'
import { MenuButton } from '../Menu/MenuButton'
import { SmallButton } from '../components/Button'
import { FilterMenu } from '../FilterMenu/FilterMenu'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import {
  hasFilters as hasFiltersSelector,
  resetFilter,
} from '../state/filter/filterSlice'

const BottomAreaContainer = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  flex: 1;
  pointer-events: none;
`

const BottomAreaContent = styled.div<{ gradientType: 'light' | 'dark' }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 1000px;
  margin: auto;
  justify-content: space-between;
  padding: 1rem 1rem calc(1em + env(safe-area-inset-bottom) * 4);
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 20%,
    rgba(0, 0, 0, 0.99) 100%
  );

  ${(props) =>
    props.gradientType === 'light' &&
    `
     background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );
  `}
  box-sizing: border-box;
  pointer-events: none;
`

const FlexDiv = styled.div`
  flex: 1;
`

type Props = {
  showFilterButton?: boolean
  gradientType?: 'light' | 'dark'
  userLocation?: Coordinate | null
}

export const BottomArea: FC<Props> = ({
  showFilterButton = true,
  gradientType = 'dark',
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)

  const dispatch = useAppDispatch()

  const hasFilters = useAppSelector(hasFiltersSelector)

  return (
    <>
      <BottomAreaContainer>
        <BottomAreaContent
          gradientType={gradientType}
          className="ios-extra-padding"
        >
          {showFilterButton && (
            <FilterButton
              isOpen={filterOpen}
              onClick={() => {
                setMenuOpen(false)
                setFilterOpen(!filterOpen)
              }}
            />
          )}
          {!showFilterButton && <FlexDiv />}
          {filterOpen && hasFilters && (
            <SmallButton
              onClick={() => {
                dispatch(resetFilter('types'))
                dispatch(resetFilter('categories'))
                dispatch(resetFilter('floors'))
              }}
            >
              Reset
            </SmallButton>
          )}
          <FlexDiv />

          <MenuButton
            isOpen={menuOpen}
            onClick={() => {
              setMenuOpen(!menuOpen)
              setFilterOpen(false)
            }}
          />
        </BottomAreaContent>
      </BottomAreaContainer>

      <Menu isVisible={menuOpen} onClose={() => setMenuOpen(false)} />

      <FilterMenu
        isVisible={filterOpen} /*onClose={() => setFilterOpen(false)}*/
      />
    </>
  )
}
