import styled from 'styled-components'
import { space } from './colors'

type RowProps = {
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  alignItems?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'baseline'
    | 'stretch'
    | 'initial'
  justifyContent?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
  align?: 'left' | 'center' | 'right'
  mr?: number
  ml?: number
  pl?: number
  pr?: number
  gap?: number
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  margin-right: ${(props) => (props.mr ? space[props.mr] : 0)}px;
  margin-left: ${(props) => (props.ml ? space[props.ml] : 0)}px;
  padding-right: ${(props) => (props.pr ? space[props.pr] : 0)}px;
  padding-left: ${(props) => (props.pl ? space[props.pl] : 0)}px;
  gap: ${(props) => (props.gap ? space[props.gap] : 0)}px;
`

type ColProps = {
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  flex?: number
  align?: 'left' | 'center' | 'right'
  mr?: number
  ml?: number
}

export const Col = styled.div<ColProps>`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};
  flex: ${(props) => (props.flex ? props.flex : 'initial')};
  text-align: ${(props) => props.align || 'left'};
  margin-right: ${(props) => (props.mr ? space[props.mr] : 0)}px;
  margin-left: ${(props) => (props.ml ? space[props.ml] : 0)}px;
`
