import styled from 'styled-components'
import {
  Body1,
  SubTitle2,
  Caption,
  colors,
  H1,
  H2,
  H3,
  H5,
  H4,
  Body2,
  SubTitle1,
} from '../components'

const Container = styled.div`
  color: ${colors.textInverted};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Demo = () => {
  return (
    <Container>
      <H1>H1 Headline</H1>
      <H2>H2 Headline</H2>
      <H3>H3 Headline</H3>
      <H4>H4 Headline</H4>
      <H5>H5 Headline</H5>
      <Body1>Body1 Headline</Body1>
      <Body2>Body2 Headline</Body2>
      <Caption>Caption Headline</Caption>
      <SubTitle1>SubTitle1 Headline</SubTitle1>
      <SubTitle2>SubTitle2 Headline</SubTitle2>
    </Container>
  )
}
