const marshland = {
  50: '#F6F7F7',
  100: '#E0E7E5',
  200: '#C1CECB',
  300: '#9BADAA',
  400: '#758C8A',
  500: '#5B716F',
  600: '#485958',
  700: '#3C4948',
  800: '#323D3C',
  900: '#2D3434',
  950: '#141919',
}

const color2024 = '#FF9580'

const palette = {
  primary: '#7A28FF',
  primaryDark: '#594C5C',
  primaryLight: '#F1E3F3',
  // primaryMedium: '#39183D',
  secondary: '#FF9580',
  secondaryLight: '#FBEEE4',
  third: '#848283',
  fourth: '#481796',
  black: '#000000',
  white: '#FFFFFF',

  grey: {
    100: '#F4F2F5',
    200: '#E8E4EA',
    300: '#D6CED9',
    400: '#BDB0C2',
    1000: '#2D242E',
  },

  blue: {
    400: '#799FB7',
  },

  textGrey: marshland[950],

  border: marshland[500],
}

const colors = {
  whiteDark: palette.secondaryLight,
  white: palette.white,

  backgroundDark: '#2b2b2b',
  backgroundBlack: palette.black,
  backgroundTransparent: '#252424cc',
  backgroundTransparent2: '#252424ee',

  text: palette.textGrey,
  textInverted: '#FFFFFF',
  textAlt: color2024,
  textForTitle: marshland[200],

  primary: palette.primary,
  primaryDark: palette.primaryDark,
  secondary: palette.secondary,

  filtersBackground: palette.primaryDark,

  highlight: palette.fourth,
  favorite: '#645556',
  favoriteLight: '#433839',

  plusButton: palette.secondary,
  plusButtonFavorite: palette.secondaryLight,

  scrollUpButton: '#c74544',
  scrollUpButtonActive: '#672222',

  timeHeader: {
    background: marshland[600],
    // background: palette.primaryDark,
    border: palette.primaryLight,
  },

  Button: {
    background: palette.primary,
    text: palette.white,
  },

  SpecialContent: {
    // bg: palette.grey[1000],
    bg: palette.black,
    bgSecondary: palette.black,
    divider: palette.primaryDark,
  },

  DayButtons: {
    activeBg: palette.white,
    activeText: palette.textGrey,
    border: palette.primary,
  },

  Entry: {
    background: 'transparent',
    // backgroundFavorite: palette.primaryDark,
    backgroundFavorite: '#2D242E',
    border: palette.border,
    floorItem: palette.primaryDark,
    favorite: {
      bg: palette.secondary,
      text: palette.black,
      floorItem: palette.grey[100],
    },
    tag: palette.blue[400],
  },

  Search: {
    textColor: palette.primaryDark,
    background: marshland[100],
    closeIconBg: palette.primary,
    closeIconBgDisabled: marshland[500],
    closeIconText: palette.white,
  },

  Filters: {
    titleBackground: marshland[600],
    border: marshland[200],
  },

  zIndex: {
    timeTable: 10,
    stickyStuff: 11,
    menu: 20,
  },

  error: {
    textColor: palette.secondary,
  },
}

const space = [0, 4, 8, 12, 16, 24, 32]

export default colors

export { colors, space }
