import styled from 'styled-components'
import colors, { space } from './colors'

export function pxToRem(px: number) {
  return px / 16 + 'rem'
}

export const FontFamilies = {
  Regular: 'ManifontGroteskBook',
  RegularBold: 'ManifontGroteskBold',
  Secondary: 'Avara',
}

const TextContent = styled.div<{ white: boolean }>`
  color: ${(props) => (props.white ? colors.textInverted : colors.text)};
  text-align: center;
  padding: 0 1rem;
`

const H1 = styled.div<{
  white?: boolean
  halfMargin?: boolean
  mr?: number
  ml?: number
  mt?: number
  mb?: number
  color?: string
}>`
  font-size: 49px;
  line-height: 1.2;
  /* font-family: 'Inter-bold'; */
  font-family: 'ManifontGroteskBold';
  /* margin: ${(props) => (props.halfMargin ? '0.3rem 0 0' : '1rem 0')}; */
  /* color: ${(props) => (props.white ? colors.text : colors.textInverted)}; */
  color: ${(props) => props.color || 'inherit'};
  margin-right: ${(props) => (props.mr ? space[props.mr] : 0)}px;
  margin-left: ${(props) => (props.ml ? space[props.ml] : 0)}px;
  margin-top: ${(props) => (props.mt ? space[props.mt] : 0)}px;
  margin-bottom: ${(props) => (props.mb ? space[props.mb] : 0)}px;
`

const H2 = styled(H1)`
  font-size: ${pxToRem(31)};
`

const H3 = styled(H1)`
  font-size: ${pxToRem(25)};
`

const H4 = styled(H1)`
  font-size: ${pxToRem(22)};
`

const H5 = styled(H1)`
  font-size: ${pxToRem(20)};
`

// Body1 Body2

const Body1 = styled.div<{ white?: boolean; inline?: boolean; color?: string }>`
  /* font-size: 16px; */
  font-family: 'Avara';
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  font-weight: 400;
  /* color: ${(props) =>
    props.color
      ? props.color
      : props.white
      ? colors.text
      : colors.textInverted}; */
  color: ${(props) => props.color || 'inherit'};
  display: ${(props) => (props.inline ? 'inline' : 'block')};
`

const Body2 = styled(Body1)`
  font-size: ${pxToRem(14)};
`

const Text = styled(Body1)<{
  small?: boolean
  white?: boolean
  inline?: boolean
}>`
  font-family: 'ManifontGroteskBook';
  font-size: ${(props) => (props.small ? pxToRem(14) : pxToRem(16))};
  line-height: 1.3;
  margin: 0.5rem 0;
`

const SubTitle1 = styled(Text)`
  /* font-size: 16px; */
  font-size: ${pxToRem(16)};
  /* font-weight: 600; */
  margin: 0;
`

const SubTitle2 = styled(SubTitle1)`
  /* font-size: 14px; */
  font-size: ${pxToRem(14)};
`

const Caption = styled(Text)`
  /* font-size: 13px; */
  font-size: ${pxToRem(13)};
  line-height: 1;
`

const BreakLongWords = styled.span`
  display: table;
  table-layout: fixed;
  width: 100%;
  word-wrap: break-word;
  border-spacing: 0;
`

const Spacer = styled.div<{ size?: number }>`
  margin-bottom: ${(props) => (props.size ? props.size * 1 + 'rem' : '1rem')};
`
const HSpacer = styled.div<{ size: number }>`
  width: ${(props) => (props.size ? props.size * 1 + 'rem' : '1rem')};
`

const Divider = styled.div`
  border-top: 1px solid ${colors.primary};
`

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  Body1,
  Body2,
  SubTitle1,
  SubTitle2,
  Text,
  TextContent,
  Caption,
  Spacer,
  HSpacer,
  Divider,
  BreakLongWords,
}
