import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import { Departure } from './Departure/Departure'
import Emergency from './Emergency'
import ImportExport from './ImportExport'
import { FestivalMap } from './Map/MapPlaceholder'
import Start from './Start/Start'
import Switcher from './TimetableList/Switcher'
import { Config } from './const'
import { LangProvider } from './state/language'
import { store } from './state/store'
import { isIOS } from './helper/userAgents'
import { StyleSheetManager } from 'styled-components'
import { checkSavedFilterValues } from './state/filter/filterSlice'
import { LanguageSelection } from './LanguageSelection/LanguageSelection'
import { NoPhotos } from './NoPhotos/NoPhotos'
import { Demo } from './Demo/Demo'
import SimpleMap from './SimpleMap/SimpleMap'

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const vh = window.innerHeight * 0.01

  console.log('resize', {
    vh,
    height: window.innerHeight,
    width: window.innerWidth,
  })

  // on IOS installed as app, the height is not correct, width and height are both the same
  if (window.innerWidth !== window.innerHeight) {
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  // on ios we need to wait a bit longer to get the new height
  setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const vh = window.innerHeight * 0.01

    console.log('resize after timeout', {
      vh,
      height: window.innerHeight,
      width: window.innerWidth,
    })
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, 300)
})

document.addEventListener('DOMContentLoaded', () => {
  // we can move only if we are not in a browser's tab
  const isBrowser = matchMedia('(display-mode: browser)').matches
  if (!isBrowser) {
    window.moveTo(16, 16)
    window.resizeTo(600, 800)
  }

  const isStandalone = matchMedia('(display-mode: standalone)').matches
  const isFullscreen = matchMedia('(display-mode: fulscreen)').matches

  if (isIOS && (isStandalone || isFullscreen)) {
    document.body.classList.add('ios-standalone')
  }
})

class App extends Component {
  constructor(props) {
    super(props)
    // const loadedState = loadState()
    this.state = {}
  }

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log('App startet. Version: ' + Config.VERSION)
    store.dispatch(checkSavedFilterValues())
  }

  render() {
    return (
      <div className="App">
        <LangProvider>
          <Provider store={store}>
            <StyleSheetManager
              shouldForwardProp={(prop) =>
                ![
                  'isFavorite',
                  'activeDay',
                  'isSticky',
                  'marginRight',
                  'alignItems',
                  'flexDirection',
                  'gradientType',
                  'white',
                  'isActive',
                  'isFirst',
                  'withBackground',
                  'alignLeft',
                  'withSolidBackground',
                  'isVisible',
                  'center',
                  'mr',
                  'ml',
                  'justifyContent',
                  'isLast',
                ].includes(prop)
              }
            >
              <HashRouter>
                <Routes>
                  <Route path="/" element={<LanguageSelection />} />
                  <Route path="/import/:code" element={<LanguageSelection />} />
                  <Route path="start" element={<Start />} />
                  <Route path="no-photos" element={<NoPhotos />} />

                  {!Config.REACT_APP_SHOW_NOT_REALEASED && (
                    <>
                      <Route
                        path="/timetable"
                        element={<Switcher type="timetable" />}
                      />

                      <Route
                        path="/favorites"
                        element={<Switcher type="favorites" />}
                      />
                      <Route path="/notfall" element={<Emergency />} />
                      <Route path="/importExport" element={<ImportExport />} />

                      <Route path="/demo" element={<Demo />} />

                      {/* {Config.REACT_APP_SHOW_DEPARTURE && ( */}
                      <Route path="/departure" element={<Departure />} />
                      {/* )} */}
                      {Config.REACT_APP_SHOW_MAP &&
                        Config.REACT_APP_IS_FUSION && (
                          <Route path="/map" element={<FestivalMap />} />
                        )}

                      {Config.REACT_APP_SHOW_MAP &&
                        !Config.REACT_APP_IS_FUSION && (
                          <Route path="/map" element={<SimpleMap />} />
                        )}
                    </>
                  )}
                </Routes>
              </HashRouter>
            </StyleSheetManager>
          </Provider>
        </LangProvider>
      </div>
    )
  }
}

/* <Route
                  exact
                  path={['/', '/import/:code']}
                  component={LanguageSelection}
                /> */

/*
              <Route path="/timetable/:type?" component={Switcher} />

              <Route path="/notfall" component={Emergency} />
              <Route path="/importExport" component={ImportExport} /> */

export default App
