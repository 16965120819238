import { FC } from 'react'
import styled from 'styled-components'
import { Overlay } from '../Menu/Menu'
import {
  FontFamilies,
  H5,
  Message,
  Page,
  Row,
  Spacer,
  colors,
  pxToRem,
} from '../components'
import { PageTitle } from '../components/PageTitle'
import { Config } from '../const'
import {
  floorDescriptions,
  floors as allFloors,
  types,
  categories,
} from '../state/filter/consts'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import {
  changeFilter,
  getFilters,
  openFilter,
} from '../state/filter/filterSlice'
import { InfoButton } from '../TimetableList/PlusButton'

const convertToStringOrNumber = (value: unknown): string | number => {
  if (typeof value === 'string') {
    const num = Number(value)
    return isNaN(num) ? value : num
  }
  return typeof value === 'number' ? value : String(value)
}

export const FilterButton = styled.button<{ isActive?: boolean }>`
  font-size: ${pxToRem(16)};
  /* padding: 10px 16px; */
  padding: 0 0.8rem;
  min-height: ${pxToRem(48)};
  color: ${colors.white};
  color: ${(props) => (props.isActive ? colors.text : colors.textInverted)};
  background-color: ${(props) =>
    props.isActive ? colors.white : colors.backgroundBlack};
  border: 1px solid ${colors.primary};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: ${FontFamilies.Regular};
`

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 2rem 1rem;
`

export const SectionTitle = styled(H5)`
  background-color: ${colors.Filters.titleBackground};
  padding: 1rem 0;
  text-align: center;
  border-bottom: 1px solid ${colors.Filters.border};
`

export const ScrollableContent = styled.div`
  /* flex: 1, */
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 5rem;
`

const InfoButtonContainer = styled.div`
  margin-left: ${pxToRem(12)};
`

export const FloorPrefix = styled.div<{ type: 'regular' | 'secondary' }>`
  border-radius: 1rem;
  width: 2rem;
  height: 2rem;
  background-color: ${(props) =>
    props.type === 'regular' ? colors.primary : 'white'};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.type === 'regular' ? colors.primary : colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.type === 'regular' ? colors.textInverted : colors.text};
`

type Props = {
  isVisible?: boolean
}

const showFloors = !Config.REACT_APP_SHOW_ARTISTS

function getPrefix(floor: string) {
  const [prefix] = floor.split(' | ')

  return convertToStringOrNumber(prefix)
}

const FloorName = ({
  floor,
  type,
}: {
  floor: string
  type: 'regular' | 'secondary'
}) => {
  // 1 - MYFloor
  // A - Cloud Cuckoo
  const [prefix, suffix] = floor.split(' | ')
  if (!suffix) {
    return <>{convertToStringOrNumber(prefix)}</>
  }
  return (
    <Row gap={2}>
      <FloorPrefix type={type}>{prefix}</FloorPrefix>
      {suffix}
    </Row>
  )
}

const floors = allFloors.sort((a, b) => {
  const [aPrefix] = a.split(' | ')
  const [bPrefix] = b.split(' | ')

  // Check if both prefixes are letters
  const isALetter = isNaN(aPrefix as unknown as number) && aPrefix.length === 1
  const isBLetter = isNaN(bPrefix as unknown as number) && bPrefix.length === 1

  if (isALetter && isBLetter) {
    return aPrefix.localeCompare(bPrefix)
  }

  if (isALetter) return -1
  if (isBLetter) return 1

  // If both are numbers, compare them numerically
  if (
    !isNaN(aPrefix as unknown as number) &&
    !isNaN(bPrefix as unknown as number)
  ) {
    return parseInt(aPrefix) - parseInt(bPrefix)
  }

  // If one is a number and the other isn't, the number comes after
  if (!isNaN(aPrefix as unknown as number)) return 1
  if (!isNaN(bPrefix as unknown as number)) return -1

  // For any other case, use string comparison
  return aPrefix.localeCompare(bPrefix)
})

let floorsOutdoor = floors
let floorsIndoor: string[] = []

if (!Config.REACT_APP_IS_FUSION) {
  floorsOutdoor = floors.filter((floor) => typeof getPrefix(floor) !== 'number')
  floorsIndoor = floors.filter((floor) => typeof getPrefix(floor) === 'number')
}

export const FilterMenu: FC<Props> = ({ isVisible }) => {
  const filters = useAppSelector(getFilters)
  const dispatch = useAppDispatch()

  return (
    <Overlay isVisible={isVisible}>
      <Page withSolidBackground alignLeft center>
        <PageTitle title={<Message id="TITLE_PAGE_FILTER" />} />
        <ScrollableContent>
          <SectionTitle>
            <Message id="FILTER_GENRE" />
          </SectionTitle>

          <FilterContainer>
            {Config.REACT_APP_IS_FUSION &&
              types.map((type) => (
                <FilterButton
                  key={type}
                  isActive={filters.types.includes(type)}
                  onClick={() =>
                    dispatch(changeFilter({ filterId: 'types', value: type }))
                  }
                >
                  {type}
                </FilterButton>
              ))}

            {!Config.REACT_APP_IS_FUSION &&
              categories.map((category) => (
                <FilterButton
                  key={category}
                  isActive={filters.categories.includes(category)}
                  onClick={() =>
                    dispatch(
                      changeFilter({ filterId: 'categories', value: category }),
                    )
                  }
                >
                  {category}
                </FilterButton>
              ))}
          </FilterContainer>

          {showFloors && (
            <>
              <Spacer size={2} />
              <SectionTitle>
                <Message
                  id={
                    Config.REACT_APP_IS_FUSION
                      ? 'FILTER_FLOORS'
                      : 'FILTER_FLOORS_INDOOR'
                  }
                />
              </SectionTitle>
              <FilterContainer>
                {floorsOutdoor.map((floor) => {
                  const hasInfo = floorDescriptions.de[floor]
                  const isActive = filters.floors.includes(floor)
                  return (
                    <FilterButton
                      key={floor}
                      isActive={isActive}
                      onClick={() =>
                        dispatch(
                          changeFilter({ filterId: 'floors', value: floor }),
                        )
                      }
                    >
                      <FloorName floor={floor} type="regular" />
                      {hasInfo && (
                        <InfoButtonContainer>
                          <InfoButton
                            $isFavorite={isActive}
                            $isWhite
                            $isSmall
                            onClick={(event) => {
                              event.stopPropagation()
                              event.preventDefault()
                              dispatch(
                                changeFilter({
                                  filterId: 'floorInfo',
                                  value: floor,
                                }),
                              )
                              dispatch(openFilter('floorInfo'))
                            }}
                          ></InfoButton>
                        </InfoButtonContainer>
                      )}
                    </FilterButton>
                  )
                })}
              </FilterContainer>
              <Spacer size={2} />
              <SectionTitle>
                <Message id="FILTER_FLOORS_OUTDOOR" />
              </SectionTitle>
              <FilterContainer>
                {floorsIndoor.length > 0 && (
                  <>
                    {floorsIndoor.map((floor) => {
                      const hasInfo = floorDescriptions.de[floor]
                      const isActive = filters.floors.includes(floor)
                      return (
                        <FilterButton
                          key={floor}
                          isActive={isActive}
                          onClick={() =>
                            dispatch(
                              changeFilter({
                                filterId: 'floors',
                                value: floor,
                              }),
                            )
                          }
                        >
                          <FloorName floor={floor} type="secondary" />
                          {hasInfo && (
                            <InfoButtonContainer>
                              <InfoButton
                                $isFavorite={isActive}
                                $isWhite
                                $isSmall
                                onClick={(event) => {
                                  event.stopPropagation()
                                  event.preventDefault()
                                  dispatch(
                                    changeFilter({
                                      filterId: 'floorInfo',
                                      value: floor,
                                    }),
                                  )
                                  dispatch(openFilter('floorInfo'))
                                }}
                              ></InfoButton>
                            </InfoButtonContainer>
                          )}
                        </FilterButton>
                      )
                    })}
                  </>
                )}
              </FilterContainer>
            </>
          )}
        </ScrollableContent>
      </Page>
    </Overlay>
  )
}
