import React, { ReactNode } from 'react'
import styled from 'styled-components'
import colors from './colors'
import { H4 } from './Typo'
import img from '../images/pattern_attension_24.gif'
import { ReactComponent as LeftIcon } from '../images/title-left-icon.svg'

type Props = {
  title: ReactNode
}

const Title = styled.div`
  padding: 0 1rem;
  /* background-color: ${colors.primary};
  background: url(${img}), #594c5c;
  background-blend-mode: soft-light, normal; */
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const PageTitle: React.FC<Props> = ({ title }) => {
  return (
    <Title>
      <LeftIcon />
      <H4 color={colors.textForTitle}>{title}</H4>
    </Title>
  )
}
