import React from 'react'
import styled from 'styled-components'
import { Row } from '../components/Grid'
import colors from '../components/colors'
import { FontFamilies, Message, pxToRem } from '../components'

const TimeRow = styled(Row)`
  color: white;
  /* border-top: 1px solid white; */
  border-bottom: 1px solid ${colors.timeHeader.border};
  font-family: 'ManifontGroteskBold';
  padding: 0 4px;
  :first-child {
    margin-top: 0;
  }

  background-color: ${colors.timeHeader.background};
`

const SmallTime = styled.div`
  padding: 0.6rem;
  font-size: 1.3rem;
  min-width: 4.5rem;
  text-transform: uppercase;
  text-align: left;
`

const SmallTimeRight = styled(SmallTime)`
  text-align: right;
`

const Time = styled.div`
  padding: 0.8rem 0.6rem;
  padding-top: 1.1rem;
  font-size: ${pxToRem(20)};
  flex: 1;
  text-align: center;
  font-family: ${FontFamilies.Regular};
  letter-spacing: 0.06em;
`

type Props = {
  from: string
  day: string
  withExtras?: boolean
}

const TimeHeader = ({ from, day, withExtras }: Props) => (
  <TimeRow>
    {withExtras && (
      <SmallTime>
        <Message id="DAY" data={day} />
      </SmallTime>
    )}

    <Time>{from}</Time>
    {withExtras && <SmallTimeRight> </SmallTimeRight>}
  </TimeRow>
)

export default TimeHeader
